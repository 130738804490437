<template>
  <div class="person-center-head">
    <div class="zxLogo">
      <router-link :to="{name:'home'}">
        <img src="./../../../assets/image/personCenter/certLogo.png">
      </router-link>
      <span @click="changePage('personHome')">个人中心</span>
    </div>
    <div class="personCenterRouter" v-if="headType==='person'">
      <el-button type="text" v-for="(item,index) in headList" :key="index" :class="{'avtive':currentRouter===item.id}" @click="changePage(item.path)">{{item.name}}</el-button>
    </div>
    <div class="helpAndPerson" v-if="headType==='person'">
      <el-button type="text" @click="$router.push({name:'helpList', query: { type: 5 }})">使用帮助</el-button>
      <div class="newsNoticeBox">
        <el-button type="text" class="newsNotice" @click="openNotice">
          <svg-icon :icon-class="$store.state.businessListData && $store.state.businessListData.total > 0 &&$store.state.token?'message':'not-message'" />
        </el-button>
        <div class="notice" v-if="$store.state.businessListData && $store.state.businessListData.total > 0 &&$store.state.token&&noticeShow">
          <div class="noticeTitle"><span class="el-icon-message-solid"></span> 【通知】您有{{$store.state.businessListData.total}}个证书业务单正在进行中</div>
          <p v-if="$store.state.businessListData.records[0].status == 1">
            您提交的【{{$store.state.businessListData.records[0].name}}】{{$store.state.businessListData.records[0].type|transactionTypeFilter}}业务还未上传资料，请尽快上传办理业务</p>
          <p v-if="$store.state.businessListData.records[0].status == 3">
            您提交的【{{$store.state.businessListData.records[0].name}}】{{$store.state.businessListData.records[0].type|transactionTypeFilter}}业务审核不通过，不通过原因：{{$store.state.businessListData.records[0].auditComment}}
          </p>
          <p v-if="$store.state.businessListData.records[0].status == 4">
            您提交的【{{$store.state.businessListData.records[0].name}}】{{$store.state.businessListData.records[0].type|transactionTypeFilter}}业务已经通过审核，待支付金额{{$store.state.businessListData.records[0].discountTotal}}元
          </p>
          <p v-if="$store.state.businessListData.records[0].status == 6">
            您提交的【{{$store.state.businessListData.records[0].name}}】{{$store.state.businessListData.records[0].type|transactionTypeFilter}}业务正在等待续期，请尽快进行续期。
          </p>
          <p v-if="$store.state.businessListData.records[0].status == 14">
            您提交的【{{$store.state.businessListData.records[0].name}}】{{$store.state.businessListData.records[0].type|transactionTypeFilter}}业务正在等待变更，请尽快进行变更。
          </p>
          <p v-if="$store.state.businessListData.records[0].status == 7">
            您提交的【{{$store.state.businessListData.records[0].name}}】{{$store.state.businessListData.records[0].type|transactionTypeFilter}}业务正在等待解锁，请尽快进行解锁。
          </p>

          <div class="noticeBtn" @click="getRouter">查看详情</div>
          <div class="el-icon-close close" @click="closeNotice"></div>
        </div>
      </div>
      <el-popover placement="bottom" width="280" trigger="click">
        <div class="administratorsBox">
          <svg-icon icon-class="head-sculpture" class="heads" />
          <dl>
            <dt>{{userInfo.name}}</dt>
            <dd><span :class="{'verified':userInfo.verified}"><svg-icon :icon-class="userInfo.verified?'certified':'notCertified'" />{{userInfo.verified?'已认证':'未认证'}}</span></dd>
          </dl>
          <p @click="$router.push({name:'account'})" class="el-icon-mobile-phone clearfix">账号管理<i class="el-icon-arrow-right"></i></p>
          <p @click="$router.push({name:'enterprise'})" class="el-icon-office-building clearfix">我的企业<i class="el-icon-arrow-right"></i></p>
          <el-button size="small" @click="signOut">退出登录</el-button>
        </div>
        <el-button type="primary" circle size="small" slot="reference"><svg-icon icon-class="head-sculpture" /></el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { headList } from '@/utils/options'
import { transactionType } from '@/utils/typeMatch.js'
export default {
  data () {
    return {
      number: 1,
      headList: headList,
      currentRouter: 'personHome',
      noticeShow: false
    }
  },
  filters: {
    transactionTypeFilter: transactionType
  },
  props: ['headType'],
  created () { },
  methods: {
    changePage (path) {
      if (path.split('/')[0] === this.currentRouter) {
        location.reload()
      }
      this.$router.push({ name: path })
    },
    signOut () {
      this.isLoad = true
      this.$api.removeToken().then(() => {
        sessionStorage.clear()
        this.$store.commit('SET_TOKEN', '')
        this.$store.commit('user/setUserMobile', '')
        this.$store.commit('user/setSecretList', [])
        this.$router.push({ name: 'home' })
      })
    },
    openNotice () {
      this.noticeShow = true
    },
    closeNotice () {
      this.noticeShow = false
    },
    getRouter () {
      this.closeNotice()
      this.$router.push({ name: 'serviceCenter', query: { type: 1 } })
    },
  },
  computed: {
    userInfo: {
      // 获取vuex中存储值
      get () {
        return this.$store.state.user.userInfo
      },
      // return 'this.$store.state.user.userInfo'
    },
    unreadCount () {
      return 'this.$store.state.unreadInfo.unreadMsgCount'
    },
  },
  watch: {
    $route: {
      handler (to) {
        this.currentRouter = to.path.split('/')[1]
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.person-center-head {
  width: 100%;
  height: 58px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(45, 50, 54, 0.1);
  padding-left: 24px;
  padding-right: 24px;
  position: fixed;
  top: 0;
  z-index: 9;
  .logo {
    width: 89px;
    height: 40px;
    margin-right: 12px;
    color: #fff;
  }
}
.zxLogo {
  float: left;
  height: 58px;
  display: flex;
  align-items: center;
  img {
    width: 89px;
  }
  a {
    padding-right: 16px;
  }
  span {
    position: relative;
    padding-left: 12px;
    cursor: pointer;
    &::after {
      content: '';
      width: 1px;
      height: 30px;
      background: #dee0e9;
      position: absolute;
      left: 0;
      top: -5px;
    }
  }
}
.personCenterRouter {
  float: left;
  height: 58px;
  display: flex;
  align-items: center;
  margin-left: 48px;
  ::v-deep .el-button {
    color: #303b50;
    font-size: 16px;
    margin: 0 24px;
    span {
      color: #303b50;
    }
  }
  .avtive {
    color: $primaryColor;
    position: relative;
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: -9px;
      left: 0;
      background: $primaryColor;
    }
  }
}
.helpAndPerson {
  float: right;
  height: 58px;
  display: flex;
  align-items: center;
  .el-button--text {
    font-size: 16px;
    color: #303b50;
    margin-right: 16px;
    span {
      color: #303b50;
    }
  }
  .el-button--small.is-circle {
    padding: 0;
    .svg-icon {
      font-size: 32px;
    }
  }
  .newsNotice {
    padding: 2px 16px;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    margin-right: 16px;
    color: #2a2b2c;
    .svg-icon {
      font-size: 16px;
    }
  }
  ::v-deep .el-button i {
    cursor: pointer;
  }
}
.administratorsBox {
  padding: 20px 10px 12px;
  border-radius: 8px;
  text-align: center;
  img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: #1c67f3;
  }
  dl {
    margin-top: 16px;
    padding-bottom: 34px;
    border-bottom: 1px solid #eeeff5;
  }
  dt {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    margin-bottom: 8px;
  }
  span {
    display: inline-block;
    width: 70px;
    height: 24px;
    background: #e8e8e8;
    border-radius: 2px;
    font-size: 11px;
    color: #929292;
    line-height: 24px;
  }
  .svg-icon {
    margin-right: 4px;
    vertical-align: -0.1em;
  }
  .heads {
    margin: 0;
    font-size: 60px;
  }
  .verified {
    background: #eff9eb;
    border-radius: 2px;
    border: 1px solid #d4f0c8;
    color: #52c41a;
  }
  p {
    display: inline-block;
    width: 100%;
    padding: 0 4px 0 5px;
    font-size: 14px;
    color: #303b50;
    line-height: 50px;
    text-align: left;
    border-bottom: 1px solid #eeeff5;
    cursor: pointer;
    &::before {
      margin-right: 8px;
    }
  }
  i {
    float: right;
    font-size: 16px;
    line-height: 50px;
  }
  .el-button {
    margin-top: 24px;
    padding: 7px 13px;
  }
}

.newsNoticeBox {
  position: relative;
}
.notice {
  position: absolute;
  right: 0;
  top: 50px;
  width: 378px;
  height: 148px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(31, 37, 81, 0.11);
  border-radius: 10px;
  padding: 16px;
}
.noticeTitle {
  font-size: 16px;
  color: #303b50;
  line-height: 21px;
  padding-bottom: 12px;
}
p {
  padding: 0 20px;
  font-size: 14px;
  color: #8d96a3;
  line-height: 24px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden; //溢出内容隐藏
  text-overflow: ellipsis; //文本溢出部分用省略号表示
  display: -webkit-box; //特别显示模式
  -webkit-line-clamp: 2; //行数
  line-clamp: 2;
  -webkit-box-orient: vertical; //盒子中内容竖直排
  height: 52px;
}
.noticeBtn {
  font-size: 14px;
  color: #3a6afe;
  line-height: 19px;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 16px;
  cursor: pointer;
}
.close {
  position: absolute;
  top: 12px;
  right: 14px;
  cursor: pointer;
}
</style>
