let transTypeOption = [
  {
    value: 1,
    text: '证书新办'
  },
  {
    value: 2,
    text: '证书变更'
  },
  {
    value: 3,
    text: '证书续期'
  },
  {
    value: 4,
    text: '证书注销'
  },
  {
    value: 7,
    text: '证书解锁'
  },
  {
    value: 9,
    text: '证书补办'
  }
]
let transStatusOption = [
  {
    value: '1',
    text: '待上传资料'
  },
  {
    value: '2',
    text: '审核中'
  },
  {
    value: '3',
    text: '审核未通过'
  },
  {
    value: '4',
    text: '待付款'
  },
  {
    value: '5,8',
    text: '制证中'
  },
  {
    value: '6',
    text: '待续期'
  },
  {
    value: '7',
    text: '待解锁'
  },
  {
    value: '14',
    text: '待变更'
  },
  {
    value: '9',
    text: '待领取'
  },
  {
    value: '10',
    text: '已发货'
  },
  {
    value: '11',
    text: '已完成'
  },
  {
    value: '12',
    text: '已取消'
  },
  {
    value: '13',
    text: '已作废'
  }
]
let serves = [
  {
    label: '证书申请',
    name: 'certApply',
    explain: '用户首次申请证书，可以根据需要选择具体项目'
  },
  {
    label: '证书续期',
    name: 'certRenewal',
    explain: '用户证书即将过期时，可以在线申请续期'
  },
  {
    label: '证书解锁/密码重置',
    name: 'certUnlock',
    explain: '当您忘记密码或者Ukey被锁死时，您可以进行介质解锁/密码重置'
  },
  {
    label: '证书变更',
    name: 'certChange',
    explain: '使用Ukey，输入Pin进行验证后，可以编辑需要变更的信息'
  },
  {
    label: '证书补办',
    name: 'certReissue',
    explain: '证书载体（Ukey）发生损坏或者丢失时，可以进行补办'
  },
  {
    label: '证书注销',
    name: 'certCancel',
    explain: '当私钥泄密，Ukey丢失，Ukey损坏时您可以注销'
  }
]
let userInfo = [
  {
    name: '账号管理',
    icon: 'account'
  },
  {
    name: '我的企业',
    icon: 'enterprise'
  }
]
let serviceCenter = [
  {
    name: '业务办理',
    icon: 'business'
  },
  {
    name: '数字证书业务单',
    icon: 'orders'
  },
  {
    name: '云证书API集成业务单',
    icon: 'orders'
  },
  {
    name: '网络身份认证业务单',
    icon: 'orders'
  },
  {
    name: '验签报告业务单',
    icon: 'orders'
  }
]
let invoice = [
  {
    name: '未开发票',
    icon: 'invoice'
  },
  {
    name: '已开发票',
    icon: 'invoice'
  }
]
let certType = [
  {
    name: '机构证书',
    icon: 'my-cert'
  },
  {
    name: '机构个人证书',
    icon: 'my-cert'
  }
]
let integrationList = [
  {
    name: '身份证实名认证',
    icon: 'my-cert'
  },
  {
    name: '人脸识别',
    icon: 'my-cert'
  },
  {
    name: '运营商三要素验证',
    icon: 'my-cert'
  },
  {
    name: '银行卡四要素验证',
    icon: 'my-cert'
  },
  {
    name: '企业三要素校验',
    icon: 'my-cert'
  },
  {
    name: '企业四要素校验',
    icon: 'my-cert'
  },
  {
    name: '企业三要素校验+法人认证',
    icon: 'my-cert'
  },
  {
    name: '企业四要素校验+法人认证',
    icon: 'my-cert'
  },
  {
    name: '企业对公打款验证',
    icon: 'my-cert'
  }
]
let headList = [
  {
    id: 'personHome',
    name: '首页',
    path: 'personHome'
  },
  {
    id: 'serviceCenter',
    name: '业务',
    path: 'serviceCenter'
  },
  {
    id: 'invoice',
    name: '发票',
    path: 'invoice'
  },
  {
    id: 'certificate',
    name: '证书',
    path: 'certificate'
  },
  {
    id: 'integration',
    name: '集成中心（业务合作产品）',
    path: 'integration'
  },
]
let interfaceType = [
  {
    name: '身份证实名认证'
  },
  {
    name: '运营商三要素验证'
  },
  {
    name: '银行卡四要素验证'
  },
  {
    name: '人脸识别'
  },
  {
    name: '企业三要素校验'
  },
  {
    name: '企业四要素校验'
  },
  {
    name: '法定代表人认证'
  }
]
let certStatusOption = [
  {
    value: 1,
    text: '正常'
  },
  {
    value: -1,
    text: '已过期'
  },
  {
    value: -2,
    text: '注销(已续期)'
  },
  {
    value: -3,
    text: '注销(已变更)'
  },
  {
    value: -4,
    text: '已挂失'
  },
  {
    value: -5,
    text: '已注销'
  },
  {
    value: -7,
    text: '注销(已补办)'
  }
]

let orderType = [
  {
    value: 1001,
    text: '身份证实名认证'
  },
  {
    value: 1002,
    text: '运营商三要素验证'
  },
  {
    value: 1003,
    text: '银行卡四要素认证'
  },
  {
    value: 1004,
    text: '人脸识别'
  },
  {
    value: 1005,
    text: '企业三要素校验'
  },
  {
    value: 1006,
    text: '企业四要素校验'
  },
  {
    value: 1007,
    text: '法定代表人认证'
  }
]
let productTypes = [
  {
    value: 0,
    text: '网络身份认证服务'
  },
  {
    value: 1,
    text: '云证书API集成服务(实名+证书申请 )'
  }
]
let productNorms = [
  {
    value: 0,
    text: 'API接口服务'
  },
  {
    value: 1,
    text: '网页版'
  }
]
let productStatus = [
  {
    value: 2,
    text: '待付款'
  },
  {
    value: 4,
    text: '已完成'
  },
  {
    value: 6,
    text: '已取消'
  }
]
let cloudStatus = [
  {
    value: 1,
    text: '待审核'
  },
  {
    value: 2,
    text: '待付款'
  },
  {
    value: 3,
    text: '已付款，信息确认中'
  },
  {
    value: 4,
    text: '已完成'
  },
  {
    value: 5,
    text: '审核未通过'
  },
  {
    value: 6,
    text: '已取消'
  }
]
let productDescription = [
  {
    value: 1001,
    text: '身份证实名认证'
  },
  {
    value: 1002,
    text: '运营商三要素验证'
  },
  {
    value: 1003,
    text: '银行卡四要素认证'
  },
  {
    value: 1004,
    text: '人脸识别'
  },
  {
    value: 1005,
    text: '企业三要素校验'
  },
  {
    value: 1006,
    text: '企业四要素校验'
  },
  {
    value: 1007,
    text: '法定代表人认证'
  }
]

let reportPaperStatus = [
  {
    value: -1,
    text: '已取消'
  },
  {
    value: 1,
    text: '待付款'
  },
  {
    value: '0,3,4',
    text: '出证中'
  },
  {
    value: 5,
    text: '待领取'
  },
  {
    value: 6,
    text: '已发货'
  },
  {
    value: 7,
    text: '已完成'
  }
]
let reportElectronStatus = [
  {
    value: -1,
    text: '已取消'
  },
  {
    value: 1,
    text: '待付款'
  },
  {
    value: '0,3',
    text: '出证中'
  },
  {
    value: 7,
    text: '已完成'
  }
]
let buyType = [
  {
    value: 1,
    text: '新办'
  },
  {
    value: 2,
    text: '续次'
  }
]
export { transTypeOption, transStatusOption, serves, userInfo, serviceCenter, invoice, integrationList, headList, interfaceType, certStatusOption, orderType, productTypes, productNorms, productStatus, productDescription, certType, reportPaperStatus, reportElectronStatus, cloudStatus, buyType }